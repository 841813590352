import { createApp } from "vue";
import VWave from "v-wave";
import "@/assets/css/main.scss";
import router from "@/router/router";
import { clickOutside } from "@/utils";
import App from "./App.vue";
import { createAuth0 } from '@auth0/auth0-vue';
import authConfig from "../auth_config.json";

createApp(App)
  .use(router)
  .use(VWave)
	.use(createAuth0({
		domain: authConfig.domain,
		clientId: authConfig.clientId,
		authorizationParams: {
      redirect_uri: window.location.origin,
    }
	}))
  .directive("click-outside", clickOutside)
  .mount("#app");
