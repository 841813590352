import { ref } from "vue";
import { get_with_token, post_with_token } from "@/utils";
const organizations = ref([]);
const allowedEmails = ref([]);
const loading = ref(false);
const loaded = ref(false);
let timer = undefined;

export default function useOrganizations(auth0_token) {
	const access_token = auth0_token;

  function toggleEligibleLevel(organizationId, production) {
    const eligibleLevel = production ? "PRODUCTION" : "TRIAL";
    post_with_token("/cloud-platform-admin-api/v2/organizations/command/set-eligible-level ", {
				organizationId,
				eligibleLevel,
			}, 
			access_token);
  }

  function fetchEmails() {
		get_with_token("/cloud-platform-admin-api/v2/allowed-emails", access_token)
			.then(json => {
				allowedEmails.value = json;
			});
  }
  function fetch() {
    loading.value = true;
    fetchEmails();
		get_with_token("/cloud-platform-admin-api/v2/organizations", access_token)
			.then(json => {
				organizations.value = json;
				loading.value = false;
				loaded.value = true;
			});
  }
  if (!timer) {
    timer = setInterval(fetch, 2000);
  }
  if (!loaded.value) {
    fetch();
  }
  const init = () => {};
  return {
    init,
    organizations,
    allowedEmails,
    loading,
    toggleEligibleLevel,
    loaded,
  };
}
