import { createRouter, createWebHistory } from "vue-router";
import Page from "@/views/Page";
import PageNotFound from "@/views/PageNotFound";
import Home from "@/views/Home";
import ListOrganizations from "@/views/ListOrganizations";
import Unauthorized from "@/views/Forbidden";
import { authGuard } from "@auth0/auth0-vue";

const routes = [
  { path: "/:pathMatch(.*)*", component: PageNotFound },
  { path: "/unauthorized", component: Unauthorized },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/",
    name: "Page",
    component: Page,
    props: true,
		beforeEnter: authGuard,
    children: [
      {
        path: "",
        name: "ListOrganizations",
        component: ListOrganizations,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
