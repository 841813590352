<template>
  <div>
    <AppBar style="grid-column: 1 / 13; grid-row: 1" />
    <div style="grid-column: 2 / 13; grid-row: 2">
			<div class="main-content">
					<Suspense>
						<router-view></router-view>
					</Suspense>
      </div>
    </div>
  </div>
</template>
<script>
import AppBar from "@/components/AppBar";

import { useAuth0 } from '@auth0/auth0-vue';
export default {
  components: { AppBar },
	setup() {
		const auth0 = useAuth0();
		const user = auth0.user;
		const isAdmin = user.value["https://avassa.io/roles"]?.toString() === "ACP_ADMIN";
		if (!isAdmin) {
			window.location.href="/unauthorized";
		}
	}
};
</script>
<style scoped lang="scss">
.page-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0;
  grid-auto-rows: max-content;
}
.main-content {
  padding-bottom: 32px;
}
</style>
